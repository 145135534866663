
import { computed, defineComponent, onMounted, reactive, ref } from "vue"
import ModuleMaster from "@/components/c-module-master/module-master.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { formatToDatatable } from "@/common/formaterToDatatable";
import Datatable from "@/components/c-datatable/Datatable.vue";
import Datarow from "@/components/c-datatable/Datarow.vue";
import { getModule } from "vuex-module-decorators";
import { translate } from "@/core/plugins/i18n";
import { Field, Form } from "vee-validate";
import { useRouter } from "vue-router";
import CashModule from "@/store/modules/sales copy/modules/cash";
import Select from "@/components/forms/Select.vue"
import DatePickerRange from "@/components/forms/DatePickerRange.vue"
import FilterOpeningList from "@/models/cash/FilterOpeningList";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
    components:{
          Field
        , Form
        , ModuleMaster
        , Datatable
        , Datarow
        , Select
        , DatePickerRange
    },
    setup() {
        //Propiedades
        const moduleCash = getModule(CashModule);
        const router = useRouter();
        const formSearch = ref();

        moduleCash.GET_OPENING_PAGE();
        moduleCash.GET_CASHREGISTER_COMBO();
          
        /**
         * CONFIGURACIONES DE LA TABLA
         */
        const configTable = reactive([
            { PropName: "id", HeadLabel: "id", Type: "text", VisibleInGrid: false },
            { PropName: "cashierName", HeadLabel: "Cajero", Type: "text", VisibleInGrid: true },
            { PropName: "cashRegisterName", HeadLabel: "Caja", Type: "text", VisibleInGrid: true },
            { PropName: "opening", HeadLabel: "Fecha Apertura", Type: "text", VisibleInGrid: true },
            { PropName: "initialBalance", HeadLabel: "Balance Inicial", Type: "moneda", VisibleInGrid: true },
            { PropName: "cashRegisterIncrease", HeadLabel: "Total Incremento", Type: "moneda", VisibleInGrid: true },            
            { PropName: "cashRegisterRetire", HeadLabel: "Total Retiro", Type: "monedanegativo", VisibleInGrid: true },            
            { PropName: "totalMovements", HeadLabel: "Total Movimiento", Type: "monedanegativo", VisibleInGrid: true },            
            { PropName: "diferenceClosureMovements", HeadLabel: "Total Corte", Type: "monedanegativo", VisibleInGrid: true },            
            { PropName: "isApprobal", HeadLabel: "Aprobado", Type: "badgeStatus", VisibleInGrid: true, Badge: true },            
            { PropName: "inputFolio", HeadLabel: "Folio de Entrada", Type: "cellColor", VisibleInGrid: true },
            { PropName: "inputStatus", HeadLabel: "eee", Type: "text", VisibleInGrid: false, Badge: false },            
        ]);

        //METODOS
        const handleChangePage = (page: number) => {
            moduleCash.CHANGE_PAGE({ currentPage: page });
        }

        const handleCrudAction = (action: string | number = "N", id: string) => {
            switch(action){
                case "V":
                    //eslint-disable-next-line no-case-declarations
                    const row = records.value.find(ir => ir.id === id);
                    // eslint-disable-next-line no-case-declarations
                    const paramsClose = row?.isClosed ? 1 : 0;
                    // console.log(paramsClose);
                    router.push({
                            name: "CashMovementsDetails",
                            params: {
                                id: id
                            },
                            query: {
                                nuevoparametro: paramsClose
                            }
                        });
                    break;
                case "AP":
                    sendApprovalClosed(id);
                    break;
                default:
                    break;
            }
        }

        const sendApprovalClosed = (id: string) => {
            Swal.fire({
                    title: `¿Está seguro de aprobar el cierre de caja?`,
                    text: `Se procederá a aprobar el cierre de caja y no podrá revertir la acción`,
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonColor: '#000000',
                    cancelButtonColor: '#7E829',
                    confirmButtonText: 'Si, aprobar',
                    cancelButtonText: 'Cancelar'
                    }).then((result) => {
                        if (result.isConfirmed) {
                            moduleCash.APPROVAL_CLOSED(id).then(() => {
                                moduleCash.GET_OPENING_PAGE();
                        });
                        }
                    });
        }

        const searchFilter = (data: FilterOpeningList) => {
            moduleCash.UPDATE_FILTERS(data)
        }

        const showOptionApproval = (id: string) => {
            const row = records.value.find(ir => ir.id === id);
            return row?.isClosed;
        }

        //VARIABLES COMPUTADAS
        const headers = computed(() => {
            return configTable.filter(ct => ct.VisibleInGrid).map(h => h.HeadLabel);
        });

        const renderRows = computed(() => {
            return formatToDatatable(moduleCash.getRecordsRows, configTable, 'isApprobal', 'textClosed');
        });

        const records = computed(() => moduleCash.getRecordsRows);
        const totalRecords = computed(() => moduleCash.totalRecordsOpenList);
        const sizePage = computed(() => moduleCash.paginationOpenList.pageSize);
        const lstCash = computed(() => moduleCash.getCashRegisters);
        
        
        onMounted(() => {
                setCurrentPageBreadcrumbs(translate('BREADCRUMB.MOVEMENTSCASH'), [translate('BREADCRUMB.MODULES')]);
        });

        return {
              headers
            , renderRows
            , lstCash
            , totalRecords
            , records
            , sizePage
            , router
            , formSearch

            , showOptionApproval
            , searchFilter
            , handleChangePage
            , handleCrudAction
            , sendApprovalClosed
        }
    }
})
